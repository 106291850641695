<template>
  <v-form @submit.prevent="submit">

    <p>
      Wir haben Ihnen eine E-Mail mit einem Bestätigungscode gesendet.
    </p>
    <p>
      Geben Sie bitte den Bestätigungscode aus der E-Mail ein:
    </p>

    <p>
      <v-text-field
        autofocus
        v-model="secret"
        label="Bestätigungscode"
        ref="scrollTo"
        validate-on-blur
        :rules="[rules.required]"
        :readonly="isLocked"
        :loading="timer.isStarted"
        @keyup.esc="$emit('cancel')"
      >
        <template v-slot:progress>
          <v-progress-linear
            absolute
            height="3"
            :color="timer.color"
            :value="timer.value"
          />
        </template>
      </v-text-field>
    </p>

    <p
      v-if="error"
      class="error--text text-center"
      v-text="error"
    />

    <p class="text-right">
      <v-btn
        text
        :disabled="loading"
        @click="$emit('cancel')"
      >
        Abbrechen
      </v-btn>
      <v-btn
        type="submit"
        color="primary"
        :disabled="!canSubmit"
        :loading="loading"
      >
        Weiter
      </v-btn>
    </p>

  </v-form>
</template>


<script>
import Timer from './timer'
import { required } from '@/helpers/validators'

export default {
  name: 'FormConfirm',
  props: {
    loading: Boolean
  },
  data: () => ({
    secret: '',
    rules: {required},
    timer: new Timer()
  }),
  computed: {
    isLocked() {
      return this.loading || this.timer.isEnded
    },
    isValid() {
      return !!this.secret
    },
    canSubmit() {
      return this.isValid && !this.timer.isEnded
    },
    error() {
      if (this.timer.isEnded) return Timer.TIMER_OUT_MSG
      const { error, reason } = this.$store.state.resetPassword.status
      if (!error) return null
      return reason || 'Die Bestätigungscode ist falsch!'
    }
  },
  methods: {
    submit() {
      if (this.canSubmit && !this.loading) {
        this.$emit('done', this.secret)
      }
    }
  },
  mounted() {
    const { expiresAt } = this.$store.state.resetPassword.token || {}
    if (expiresAt) {
      this.timer.start((new Date(expiresAt)).getTime())
    }
    this.$nextTick(() => {
      this.$vuetify.goTo(this.$refs.scrollTo)
    })
  },
  beforeDestroy() {
    this.timer.stop()
  }
}
</script>
