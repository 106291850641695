<template>
  <div class="fill-height">

    <AppBar v-if="user" />

    <div class="reset-password-page-content pa-1 pa-sm-3">
      <v-card class="pa-2">

        <v-card-title v-text="$route.meta.title" />

        <v-card-text class="pl-0 pr-0">
          <v-stepper
            vertical
            class="elevation-0"
            v-model="step"
          >

            <v-stepper-step
              step="1"
              :complete="step > 1"
            >
              {{ user ? 'Aktuelles Passwort eingeben' : 'E-Mail eingeben' }}
            </v-stepper-step>

            <v-stepper-content step="1">
              <component
                v-if="step === 1"
                :is="user ? 'FormCurrentPassword' : 'FormUsername'"
                :loading="isLoading"
                @done="onStepOneDone"
                @cancel="cancel"
              />
            </v-stepper-content>

            <v-stepper-step
              step="2"
              :complete="step > 2"
            >
              Bestätigungscode eingeben
            </v-stepper-step>

            <v-stepper-content step="2">
              <FormConfirm
                v-if="step === 2"
                :loading="isLoading"
                @done="checkSecret"
                @cancel="cancel"
              />
            </v-stepper-content>

            <v-stepper-step
              step="3"
              :complete="step > 3"
            >
              Neues Passwort speichern
            </v-stepper-step>

            <v-stepper-content step="3">
              <FormNewPassword
                v-if="step === 3"
                :loading="isLoading"
                @done="setPassword"
                @cancel="cancel"
              />
            </v-stepper-content>

          </v-stepper>
        </v-card-text>

      </v-card>
    </div>
  </div>
</template>


<script>
import AppBar from '@/components/app-bar'
import FormUsername from '@/pages/reset-password.page/form-username'
import FormCurrentPassword from '@/pages/reset-password.page/form-current-password'
import FormConfirm from '@/pages/reset-password.page/form-confirm'
import FormNewPassword from '@/pages/reset-password.page/form-new-password'

export default {
  name: 'ResetPasswordPage',
  components: {
    AppBar,
    FormUsername,
    FormCurrentPassword,
    FormConfirm,
    FormNewPassword
  },
  data: () => ({
    step: 1,
    username: '',
    secret: ''
  }),
  computed: {
    isLoading() {
      const { state } = this.$store
      const { status } = state.resetPassword
      return !!status.isRequesting
          || !!status.isUpdating
          || !!state.auth.status.isLoggingIn
    },
    user() {
      return this.$store.state.user
    }
  },
  created() {
    this.$store.commit('resetPassword/RESET_STATE')
  },
  beforeDestroy() {
    this.$store.commit('resetPassword/RESET_STATE')
  },
  methods: {
    async onStepOneDone(event) {
      if (this.user) {
        try {
          await this.checkCurrentPassword(event)
          this.setUsername(this.user.email)
        } catch (e) {
          if (!e.response) {
            console.error(e)
          }
        }
      } else {
        this.setUsername(event)
      }
    },
    checkCurrentPassword(currentPassword) {
      return this.$store.dispatch('resetPassword/verifyCurrentPassword', currentPassword)
    },
    async setUsername(username) {
      this.username = ''
      await this.$store.dispatch('resetPassword/create', {username})
      this.username = username
      this.step = 2
    },
    async checkSecret(secret) {
      this.secret = ''
      await this.$store.dispatch('resetPassword/update', {secret})
      this.secret = secret
      this.step = 3
    },
    async setPassword(password) {
      const { secret } = this
      const { dispatch } = this.$store
      await dispatch('resetPassword/update', {secret, password})
      const { username } = this
      if (this.user) {
        this.$router.push({name: 'user-profile'})
      } else {
        await dispatch('auth/login', {username, password})
        this.$router.push('/')
      }
    },
    cancel() {
      const name = this.user ? 'user-profile' : 'login'
      this.$router.push({name})
    }
  }
}
</script>


<style lang="scss" scoped>
.reset-password-page-content {
  max-width: 600px;
  margin: 0 auto;
  overflow: auto;
}
</style>
