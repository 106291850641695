<template>
  <v-app-bar
    dense
    color="white"
    class="flex-grow-0 print-no-shadow"
  >
    <v-app-bar-nav-icon
      class="d-flex d-lg-none d-print-none"
      @click="toggleNavigationDrawer()"
    />
    <v-app-bar-title
      class="subtitle-1"
      v-text="title"
    />
    <v-spacer />
    <div class="d-flex d-print-none">
      <slot />
    </div>
  </v-app-bar>
</template>


<script>
export default {
  name: 'AppBar',
  computed: {
    title() {
      return this.$route.meta.title
    }
  },
  methods: {
    toggleNavigationDrawer(show) {
      this.$store.commit('TOGGLE_NAVIGATION_DRAWER', show)
    }
  }
}
</script>


<style lang="scss" scoped>
@media print {
  .v-app-bar-title {
    font-size: 16pt !important;
    font-weight: 500;
  }
}
</style>
