<template>
  <v-form @submit.prevent="submit">

    <p>
      Geben Sie bitte neues Passwort ein:
    </p>

    <p class="mb-0">
      <v-text-field
        autofocus
        counter
        validate-on-blur
        v-model="password"
        label="Neues Passwort"
        ref="scrollTo"
        :type="showPasswords ? 'text' : 'password'"
        :append-icon="showPasswords ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, rules.password]"
        :readonly="isLocked"
        :loading="timer.isStarted"
        @click:append="showPasswords = !showPasswords"
        @blur="showPasswords = false"
        @keyup.esc="$emit('cancel')"
      >
        <template v-slot:progress>
          <v-progress-linear
            absolute
            height="3"
            :value="timer.value"
            :color="timer.color"
          />
        </template>
      </v-text-field>
    </p>

    <p class="mb-0">
      <v-text-field
        counter
        v-model="password2"
        label="Neues Passwort wiederholen"
        :type="showPasswords ? 'text' : 'password'"
        :append-icon="showPasswords ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.confirmed]"
        :readonly="isLocked"
        @click:append="showPasswords = !showPasswords"
        @blur="showPasswords = false"
        @keyup.esc="$emit('cancel')"
      />
    </p>

    <div class="mb-4 caption">
      <div>
        Neues Passwort soll:
      </div>
      <div
        v-for="(test, i) in tests"
        :key="i"
        :set="isOk = test.test(password)"
      >
        <v-icon
          small
          class="tests-item-icon"
          :color="isOk ? 'success' : 'warning'"
        >
          mdi-{{ isOk ? 'check-circle' : 'alert' }}
        </v-icon>
        <span
          class="ml-1"
          v-text="test.title"
        />
      </div>
    </div>

    <p
      v-if="error"
      class="error--text text-center"
      v-text="error"
    />

    <p class="text-right">
      <v-btn
        text
        :disabled="loading"
        @click="$emit('cancel')"
      >
        Abbrechen
      </v-btn>
      <v-btn
        type="submit"
        color="primary"
        :disabled="!canSubmit"
        :loading="loading"
      >
        Speichern
      </v-btn>
    </p>

  </v-form>
</template>


<script>
import Timer from './timer'
import { required } from '@/helpers/validators'

const isLongEnough =  value => /(?=.{6,})/.test(value)
const hasAlpha =   value => /(?=.*[a-zäöü])(?=.*[A-ZÄÖÜß])/.test(value)
const hasNum =     value => /(?=.*[0-9])/.test(value)
const hasSpecial = value => /(?=.*[-_~!?@#$%^&*(){}[\]=+<>\\|/;:,.`'"])/.test(value)

export default {
  name: 'FormNewPassword',
  props: {
    loading: Boolean
  },
  data() {
    return {
      password: '',
      password2: '',
      showPasswords: false,
      rules: {
        required,
        password: value => isLongEnough(value) && hasAlpha(value) && hasNum(value) && hasSpecial(value) || 'Das Passwort ist zu einfach!',
        confirmed: value => !value || value === this.password || "Die Kennworte sind nicht gleich!"
      },
      tests: [
        {title: 'mindestens sechs Zeichen lang sein', test: isLongEnough},
        {title: 'Groß- und Kleinbuchstaben enthalten', test: hasAlpha},
        {title: 'Zahlen enthalten', test: hasNum},
        {title: 'Sonderzeichen enthalten', test: hasSpecial},
        {title: 'wiederholt sein', test: value => !!this.password2 && value === this.password2}
      ],
      timer: new Timer()
    }
  },
  computed: {
    isLocked() {
      return this.loading || this.timer.isEnded
    },
    isValid() {
      const { password, password2 } = this
      return isLongEnough(password)
          && hasAlpha(password)
          && hasNum(password)
          && hasSpecial(password)
          && password === password2
    },
    canSubmit() {
      return this.isValid && !this.timer.isEnded
    },
    error() {
      if (this.timer.isEnded) return Timer.TIMER_OUT_MSG
      const { error, reason } = this.$store.state.resetPassword.status
      if (!error) return null
      return reason || 'Fehler!'
    }
  },
  methods: {
    submit() {
      if (this.canSubmit && !this.loading) {
        this.$emit('done', this.password)
      }
    }
  },
  watch: {
    password() {
      this.password2 = ''
    }
  },
  mounted() {
    const { expiresAt } = this.$store.state.resetPassword.token || {}
    if (expiresAt) {
      this.timer.start((new Date(expiresAt)).getTime())
    }
    this.$nextTick(() => {
      this.$vuetify.goTo(this.$refs.scrollTo)
    })
  },
  beforeDestroy() {
    this.timer.stop()
  }
}
</script>
