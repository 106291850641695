<template>
  <v-form @submit.prevent="submit">

    <p>
      Geben Sie bitte Ihr aktuelles Passwort an:
    </p>

    <p>
      <v-text-field
        autofocus
        v-model="currentPassword"
        label="Aktuelles Passwort"
        validate-on-blur
        :type="showCurrentPassword ? 'text' : 'password'"
        :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required]"
        :readonly="loading"
        @click:append="showCurrentPassword = !showCurrentPassword"
        @blur="showCurrentPassword = false"
        @keyup.esc="$emit('cancel')"
      />
    </p>

    <p
      v-if="error"
      class="error--text text-center"
      v-text="error"
    />

    <p class="text-right">
      <v-btn
        text
        :disabled="loading"
        @click="$emit('cancel')"
      >
        Abbrechen
      </v-btn>
      <v-btn
        type="submit"
        color="primary"
        :disabled="!isValid"
        :loading="loading"
      >
        Weiter
      </v-btn>
    </p>

  </v-form>
</template>


<script>
import { required } from '@/helpers/validators'

export default {
  name: 'FormCurrentPassword',
  props: {
    loading: Boolean
  },
  data: () => ({
    currentPassword: '',
    showCurrentPassword: false,
    rules: {required}
  }),
  computed: {
    isValid() {
      return !!this.currentPassword
    },
    error() {
      const { error } = this.$store.state.resetPassword.status
      return error ? 'Das Passwort ist nicht gültig!' : null
    }
  },
  methods: {
    submit() {
      this.$emit('done', this.currentPassword)
    }
  }
}
</script>
