<template>
  <v-form @submit.prevent="submit">

    <p>
      Geben Sie bitte Ihre E-Mail-Adresse an:
    </p>

    <p>
      <v-text-field
        autofocus
        v-model="username"
        label="E-Mail"
        validate-on-blur
        :rules="[rules.required, rules.email]"
        :readonly="loading"
        @keyup.esc="$emit('cancel')"
      />
    </p>

    <p
      v-if="error"
      class="error--text text-center"
      v-text="error"
    />

    <p class="text-right">
      <v-btn
        text
        :disabled="loading"
        @click="$emit('cancel')"
      >
        Abbrechen
      </v-btn>
      <v-btn
        type="submit"
        color="primary"
        :disabled="!isValid"
        :loading="loading"
      >
        Weiter
      </v-btn>
    </p>

  </v-form>
</template>


<script>
import { required, email } from '@/helpers/validators'
import { isEmailValid } from '@/helpers/email'

export default {
  name: 'FormUsername',
  props: {
    loading: Boolean
  },
  data: () => ({
    username: '',
    rules: {required, email}
  }),
  computed: {
    isValid() {
      return !!this.username && isEmailValid(this.username)
    },
    error() {
      const { error, reason } = this.$store.state.resetPassword.status
      if (!error) return null
      return reason || 'Fehler!'
    }
  },
  methods: {
    submit() {
      this.$emit('done', this.username)
    }
  }
}
</script>
