export default class Timer {
  static TIMER_OUT_MSG = 'Die Zeit ist um. Versuchen Sie bitte nochmal!'

  constructor() {
    this.isStarted = false
    this.isEnded = false
    this.value = 100
    this.color = 'info'
    this.timeoutId = null
  }

  start(endTime) {
    const duration = endTime - Date.now()

    const updateTimer = () => {
      const now = Date.now()
      const value = Math.round((endTime - now) / duration * 10000) / 100
      this.value = value
      this.color = value > 30 ? 'info' : (value > 10 ? 'warning' : 'error')

      if (value > 0) {
        this.timeoutId = setTimeout(updateTimer, 100)
      } else {
        this.isEnded = true
      }
    }

    this.isEnded = false
    updateTimer()
    this.isStarted = true
  }

  stop() {
    clearTimeout(this.timeoutId)
  }
}
